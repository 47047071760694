import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <Helmet>
        <script type="text/javascript">{`
        (function(){
          var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
          i.src = "https://cdn.curator.io/published/64590b28-e330-43d2-a8ee-977195de720e.js";
          e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
        })();
        `}</script>
      </Helmet>

      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      <header className="page-head">
        <h2 className="page-head-title">
          Leia S. Chang
        </h2>
      </header>

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <sup>Updated: Dec 2023</sup>
          <hr />
          <p>
          Leia S. Chang (they/them, b. 1995) is an artist, creative technologist, and educator exploring the complexities of identity from their perspective as a second-generation non-binary Asian American. With a strong command over creative fabrication and materiality, Leia integrates digital media into physical forms to build interactive installations that spotlight the connection between memory, identity, and storytelling. Leia's work captures ephemeral moments in both poetic texts and tangible objects made from wood, resin, and other found material.
          <br/>
          <br/>
          Leia was a member for the 2023 Gray Area Incubator and has shown work at The Blanc Gallery, Gray Area SF, SYZYGY CoOp, and Resistor NYC,  has co-curated an exhibition on Useless Machines with artist/educator Blair Simmons. They organize and facilitate workshops on digital fabrication and creative coding. Leia holds an MPS from NYU TISCH’s Interactive Telecommunications Program (2023), and a BS in Computer Science from California Polytechnic University of San Luis Obispo (2017).

          </p>
          <br/>
          <h3 id="Featured">Otherwise seen at ...</h3>
          <ul>
            <li>Organizer <a href='https://fidgetcamp.com'>FidgetCamp</a> | MadSci SF - 2024</li>
            <li><a href='https://hudsonweekly.com/spamtember-unveils-playful-creativity-a-humorous-exploration-of-cultural-identity-at-all-street-gallery/'>SPAMtember</a> | All St Gallery - 2023</li>
            <li>Speaker at <a href="https://ashherr.github.io/tiat/">tiat 5 & 6</a> | SF - 2023</li>
            <li><a href='https://grayarea.org/event/gray-area-incubator-salon-2023-2/'>Incubator Artist Salon</a> | Gray Area - 2023</li>
            <li><a href='https://www.eventbrite.com/e/studio-45-presents-mid-conversation-a-solo-exhibition-by-leia-s-chang-tickets-714190160997'>mid conversation</a>, solo show | <a href='https://www.workatthestudio.com'>Studio 45</a> - 2023</li>
            <li><a href='https://www.instagram.com/p/CqMdQHeNFD9/'>After the Imagination</a> - 2023</li>
            <li><i>Guest Speaker</i> Geopositioning Genealogy (prof. Tanika I. Williams)| NYU ITP/IMA - 2023</li>
            <li><i>Co-Curator</i> <a href='https://www.theblanc.art/exhibition/useless-machines-a-curated-student-show'>Useless Machines</a> | Blanc Gallery - 2023 </li>
            <li><i>Graduate Assistant</i> Useless Machines (prof. Blair Simmons)| NYU IMA - 2023</li>
            <li>Adjacent <a href='https://adjacent-ecoscope.itp.io/Noting-the-Details'>Issue 10: endoscope</a> - 2023</li>
            <li><i>Guest Critic</i> Largescale Kinetic Sculptures (prof. Phil Caridi)| NYU ITP/IMA - 2023</li>
            <li><i>Graduate Assistant</i> Digital Fabrication (prof. Phil Caridi)| NYU IMA - 2022</li>
            <li>SYZYGY SF | <a href='https://www.syzygysf.com/event-details/sell-out-art-show'>Sell Out Art Show</a> - 2022</li>
            <li><i>Facilitator</i> Feedback Collective | NYU ITP - 2022-23</li>
          </ul> 
          <br/>
          <p>
            Currently noodling: zines as a medium, <a href="https://annaylin.com/100-days/">Anna Lin's 100 days of websites</a>, <a href="https://www.harpercollins.com/products/all-about-love-bell-hooks?variant=32894817435682">All About Love by Bell Hooks</a>, <a href="https://books.google.com/books/about/Racial_Melancholia_Racial_Dissociation.html?id=DdqCDwAAQBAJ&source=kp_book_description">Racial Melancholia, Racial Dissociation</a>, <a href="https://www.proseplay.net">Prose Play</a>. 
          </p>
          <hr />
        </div>
        <div id="curator-feed-default-feed-layout">
          <a href="https://curator.io" target="_blank" class="crt-logo crt-tag">
            Powered by Curator.io
          </a>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  logo: file(relativePath: {eq: "logo_circle.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

const About = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)

export default About
